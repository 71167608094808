
import { Component, Vue, Watch } from "vue-property-decorator";
import Footer from "@/components/Footer.vue";

@Component({
  name: "NotFound",
  components: {
    Footer
  }
})
export default class NotFound extends Vue {
}
